@tailwind base;
body {
  font-family: 'Roboto';
  font-size: 0.75rem;
}

h1 {
  @apply text-2xl;
}
h2 {
  @apply text-xl;
}
h3 {
  @apply text-lg;
}

.tooltip .tooltip-text {
  @apply invisible z-50;
}

.tooltip:hover .tooltip-text {
  @apply visible;
}

/* .package:hover {
  @apply shadow-2xl bg-bluedWhite text-eBlue;
}

.package:hover .tickIcon {
  @apply text-green-500;
}

.package:hover a.bg-grayButton {
  @apply text-white bg-deepBlue;
}

.package:hover a.bg-grayButton:active {
  @apply bg-deepBlue;
}
.package:hover a.bg-grayButton:hover {
  @apply bg-deepBlue;
} */

.farm-data-menu ul li:hover > div > button.farm-edit {
  @apply inline-block;
}

.field-menu-item:hover > div.action-buttons {
  @apply visible text-gray-600;
}

.mapboxgl-popup-close-button {
  @apply rounded-full absolute bg-white text-red-500 cursor-pointer w-15 h-15 pb-2 -right-07 -top-07 leading-1;
}

.mapboxgl-popup-close-button:hover {
  @apply bg-white;
}

.tooltip .tooltip-text {
  @apply invisible absolute z-50 inline-block ml-4 text-sm w-64 top-20 rounded-lg bg-gray-700 text-white;
}

.tooltip:hover .tooltip-text {
  @apply visible;
}

@tailwind components;
@tailwind utilities;

.focus\:outline-none:focus {
  outline: none !important;
  outline-offset: 0 !important;
  box-shadow: none !important;
}

.react-tagsinput-tag {
  @apply rounded-lg !important px-2 !important p-1 !important m-0.5 !important font-normal !important bg-grayButtonBorder !important inline-block !important text-black !important border-none !important;
}

.bottom-full {
  bottom: 100%;
}
.top-full {
  top: 100%;
}
.mapboxgl-ctrl-geocoder {
  width: 100% !important;
  border-width: 1px;
  border-color: #dbd8d6;
}
.inactive {
  background-image: url('../../../public/inactive-block-overlay.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
@media screen and (min-width: 640px) {
  .mapboxgl-ctrl-geocoder {
    max-width: none !important;
  }
}
.mapboxgl-ctrl-geocoder--input {
  padding: 6px 35px !important;
  border-radius: 4px;
}

.select-search input {
  padding-left: 10px;
}

.soil-table th {
  @apply whitespace-nowrap;
}
.shade-one {
  background: #08345f !important;
}
.shade-two {
  background: #00519a !important;
}
.shade-three {
  background: #2167b1 !important;
}
.shade-four {
  background: #4788c8 !important;
}
.shade-five {
  background: #5c96d0 !important;
}
.select-search input {
  @apply focus:outline-none;
}

.legendContainer {
  width:130px;
  padding: 15px;
  background-color: #ffffff;
}
.legendTextContainer {
  width:100%;
}
.hsLegendLeft {
  float:left;
  width:25px;
}

.hsLegendCenter {
  margin:0 auto;
  width:25px;
}

.hsLegendRight {
  float: right;
  width:25px;
}

.react-select__control {
  padding: 0.4rem 0.2rem;
}
@media only screen and (max-width: 640px) {
  .react-select__control {
    padding: 0.2rem;
  }
}

/* css for react multi email input - pdfeditor.tsx */
.multi-email [type='text']:focus {
  border-color: transparent !important;
  box-shadow: none !important;
}
.multi-email .react-multi-email.focused {
  border-color: #667eea !important;
  border-width: 2px;
}
.multi-email .react-multi-email.empty > span[data-placeholder] {
  color: #4a5568 !important;
}
.multi-email .react-multi-email > span[data-placeholder] {
  position: unset !important;
}
.fieldListDropDown input {
  @apply focus:outline-none;
}
.toggle-checkbox:checked {
  right: 0;
}
.fieldListDropDown .css-1s2u09g-control,
.fieldListDropDown .css-1pahdxg-control {
  min-height: auto !important;
  border-radius: 0 !important;
}
.fieldListDropDown .css-qc6sy-singleValue {
  overflow: visible;
}
.fieldListDropDown .ValueContainer {
  padding: 0 8px !important;
}
.fieldListDropDown .css-qc6sy-singleValue div {
  margin-left: 0 !important;
}

.fieldListDropDown .css-tlfecz-indicatorContainer,
.fieldListDropDown .css-1gtu0rj-indicatorContainer {
  padding: 2px !important;
}
.fieldListDropDown .css-14dclt2-Input {
  margin: 0 !important;
}
.jLFlOu:before {
  content: '' !important;
}

.service-sheet .react-modal-sheet-container {
  background-color: #f6f6f6 !important;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}
.numberType input[type='number']::-webkit-inner-spin-button,
.dateType input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.dateTypeMobile input[type='date']:before {
  content: attr(placeholder) !important;
}
.dateTypeMobile input[type='date']:focus:before {
  content: '' !important;
  background-color: red !important;
}

.customRadioType input[type='radio']:checked {
  border-color: #2563eb !important;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.mapboxgl-popup-tip {
  border: none !important;
}
.mapboxgl-popup-content {
  background: none !important;
  box-shadow: none !important;
}
.mapboxgl-popup {
  left: 17px !important;
}
#hint .rah-input-wrapper {
  width: 100%;
}

.cluster-marker {
  color: #fff;
  background: #F0B429;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.drainage-sheet .react-modal-sheet-container {
  background-color: #edf2f7 !important;
  box-shadow: none !important;
}

.drainage-sheet {
  z-index: 40 !important;
}

.betaFeatureErrorMessage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.betaFeatureErrorMessageInner {
  position: relative;
  padding: 12px;
  background-color: #fff;
  @apply rounded-lg
}

.betaFeatureErrorMessageInner .close-btn{
  @apply rounded-full absolute bg-white text-red-500 cursor-pointer w-15 h-15 pb-2 -right-07 -top-07 leading-1;
}
.legend-swatch{
  border-left: 5px solid;
  border-left-color: #FFFFFF;
  border-bottom: 5px solid transparent;
  bottom: 0;
  content: "";
  left: -5px;
}