/*css for react bottom drawer -mobile*/
.react-modal-sheet-content {
  height: 600px !important;
  flex: 1 1 100% !important;
  padding-bottom: 90%;
}

#form-data::-webkit-scrollbar {
  width: 6px !important;
}
#form-data::-webkit-scrollbar-thumb {
  background-color: #107fc3 !important ;
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}
.sheetScroll {
  display: flex;
  flex-direction: column;
  padding-top: 0px;
  overflow: auto;
}
.react-modal-sheet-container {
  background-color: rgba(255, 255, 255, 0.8) !important;
  border-radius: 0 !important;
}
@media only screen and (max-height: 812px) {
  .react-modal-sheet-container {
    padding-bottom: 130% !important;
  }
  .drainage-sheet .react-modal-sheet-container {
    padding-bottom: 0% !important;
  }
}

.drainage-sheet .react-modal-sheet-content {
  padding-bottom: 2% !important;
}